import { FaBars } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import styled from "@emotion/styled";

export const Nav = styled.nav`
  background: transparent;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`;

export const NavLink = styled(ScrollLink)`
  color: rgb(119, 119, 121);
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: #f6f6f6;
  }
`;
export const Logo = styled("div")`
  img {
    width: 47px;
    height: 47px;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  .menu-item + .menu-item {
    margin-left: 1rem;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  a {
    color: #2e073f; /* Text color */
    background-color: #ebd3f8; /* Button background color */
    text-decoration: none;
    padding: 10px 20px; /* Button padding */
    border-radius: 5px; /* Optional rounded corners */
    font-size: 1rem; /* Adjust font size */
    font-weight: bold; /* Optional bold text */

    &:hover {
      background-color: #d0b0f5; /* Hover effect */
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
