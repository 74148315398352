import "./App.css";
import Home from "./pages/Home";
import React from 'react';

function App() {
  return (
      <Home />
  );
}

export default App;
